.App {
  text-align: center;
}

.enter-username {
  font-size: 18px;
  padding: 12px;
  border: none;
  border-radius: 5px;
  outline: none;

}

.enter-username:focus {
  outline: none;
}

.get-btn {
  margin: 2px;
  padding: 10px;
}

* {
  outline: none;
}

body {
  background-color: #607d8b;
}

.searchbox-wrap {
  display: flex;
  width: 500px;
  margin-top: 8%;
  margin-left: auto;
  margin-right: auto;

}

.url {
  flex: 1;
  padding:  18px 15px;
  font-size: 18px;

  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: none;
  border: none;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.19);

}

.btn {

  background-color: #fff;
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  /* box-shadow: 5px 4px 6px rgba(0, 0, 0, 0.19); */
  border: none;
  cursor: pointer;
  cursor: hand;
  padding: 15px;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 300;
  color: #fff;
  background-color: #F54E59;
}

.btn:disabled{
  

  background-color: #8f8f8f;
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 5px 4px 6px rgba(0, 0, 0, 0.19);
  border: none;
  cursor: pointer;
  cursor: hand;
}

.btn-text {
  padding: 15px;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 300;
  color: #fff;
  background-color: #F54E59;
  border-radius: 10px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.19);
}

.btn-text:hover {
  background-color: #d6121f;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.19);
}



.search-area {
  display: flex;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}